export enum AuthFunction {
  PAGE_EVIDENCE = 'PAGE_EVIDENCE',
  PAGE_REFUND = 'PAGE_REFUND',
  PAGE_SALES = 'PAGE_SALES',
  COMPONENT_BASICINFO = 'COMPONENT_BASICINFO',
  COMPONENT_REPORTINFO = 'COMPONENT_REPORTINFO',
  COMPONENT_REFUNDINFO = 'COMPONENT_REFUNDINFO',
  FUNCTION_ALARMTALK_PAYMENT = 'FUNCTION_ALARMTALK_PAYMENT',
  FUNCTION_ALARMTALK_ACCIDENT = 'FUNCTION_ALARMTALK_ACCIDENT',
  FUNCTION_ALARMTALK_ACCOUNT = 'FUNCTION_ALARMTALK_ACCOUNT',
  FUNCTION_ALARMTALK_NOTICE = 'FUNCTION_ALARMTALK_NOTICE',
  FUNCTION_ALARMTALK_DOCUMENT = 'FUNCTION_ALARMTALK_DOCUMENT',
  FUNCTION_ALARMTALK_CS = 'FUNCTION_ALARMTALK_CS',
  FUNCTION_ALARMTALK_CONFIRM = 'FUNCTION_ALARMTALK_CONFIRM',
  FUNCTION_MODIFY_MESSAGE = 'FUNCTION_MODIFY_MESSAGE',
  FUNCTION_REPORT_COLLECT = 'FUNCTION_REPORT_COLLECT',
  FUNCTION_ALARMTALK_AMT_CHANGE = 'FUNCTION_ALARMTALK_AMT_CHANGE',

  PERMISSION_PRIVATE_ACCESS = 'PERMISSION_PRIVATE_ACCESS',

  FUNCTION_MSG_RESALECALL = 'FUNCTION_MSG_RESALECALL',
  FUNCTION_MSG_TOUCHINGCALL = 'FUNCTION_MSG_TOUCHINGCALL',
  FUNCTION_MSG_CANCEL_DEFENCE = 'FUNCTION_MSG_CANCEL_DEFENCE',
  FUNCTION_MSG_TAXOFFICE = 'FUNCTION_MSG_TAXOFFICE',
  FUNCTION_MSG_CUSTOMERHELP = 'FUNCTION_MSG_CUSTOMERHELP',
  FUNCTION_MSG_COMMON = 'FUNCTION_MSG_COMMON',
  PAGE_ONEMIN = 'PAGE_ONEMIN',
  PAGE_CORPTAX = 'PAGE_CORPTAX',
  PAGE_CARE_SUBSCRIPTION = 'PAGE_CARE_SUBSCRIPTION',
  PAGE_CARE_CMS = 'PAGE_CARE_CMS',
  PAGE_CARE_PAYMENT = 'PAGE_CARE_PAYMENT',
  PAGE_CARE_PRO = 'PAGE_CARE_PRO',
  PAGE_REFUND_DEAL = 'PAGE_REFUND_DEAL',
  PAGE_RESOURCE_CENTER = 'PAGE_RESOURCE_CENTER',
  PAGE_CARE_USER_MANAGEMENT = 'PAGE_CARE_USER_MANAGEMENT',
  PAGE_REFUND_LANDING_MODAL = 'PAGE_REFUND_LANDING_MODAL',
  PAGE_ENVIRONMENT_VAR = 'PAGE_ENVIRONMENT_VAR',
  FUNCTION_EDIT_ENV_VAR = 'FUNCTION_EDIT_ENV_VAR'
}
