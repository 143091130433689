import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar
} from './ui/sidebar'
import { signOut, useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import { useCookies } from 'react-cookie'
import { Fragment, useEffect, useState } from 'react'
import { Button, Collapsible, CollapsibleContent, CollapsibleTrigger } from './ui'
import { ChevronDown, UserRoundCog } from 'lucide-react'
import {
  NEXT_PUBLIC_CONSOLE_CARE_URL,
  NEXT_PUBLIC_CONSOLE_OP_URL,
  NEXT_PUBLIC_CONSOLE_REFUND_URL
} from '@zent-auth/configs/ZENT_ENV'
import { AuthFunction } from '@zent-auth/types/enums/AuthFunction'

enum MENU_CATEGORY {
  REFUND,
  REFUND_OP,
  SALES,
  CARE,
  CARE_PRO_BUSINESS,
  CARE_PRO_TAXPAYERS,
  CARE_PRO_BOOK,
  CARE_PRO_PAYROLL,
  CARE_PRO_CERTIFICATION,
  CARE_PRO_FILE,
  CARE_PRO_ALARM,
  CARE_PRO_CRM
}

const RootSidebar = () => {
  const CARE_CONSOLE_AUTHS: string[] = [
    AuthFunction.PAGE_CARE_SUBSCRIPTION,
    AuthFunction.PAGE_CARE_PAYMENT,
    AuthFunction.PAGE_CARE_PAYMENT
  ]

  const isContainsCareAuth = () => {
    return data && data.user && data.user?.functions.some((auth) => CARE_CONSOLE_AUTHS.includes(auth))
  }

  const checkInitialMenuCategoryOpen = () => {
    const { pathname } = document.location
    if (pathname.includes('/refund-service')) {
      return MENU_CATEGORY.REFUND
    } else if (pathname.includes('/refund') || pathname.includes('/csform/') || pathname.includes('/deal')) {
      return MENU_CATEGORY.REFUND_OP
    } else if (pathname.includes('/sales')) {
      return MENU_CATEGORY.SALES
    } else if (pathname.includes('/subscriptions') || pathname.includes('/payments') || pathname.includes('/cms')) {
      return MENU_CATEGORY.CARE
    } else if (pathname.includes('/pro/org')) {
      return MENU_CATEGORY.CARE_PRO_BUSINESS
    } else if (pathname.includes('/pro/taxpayers') || pathname === '/pro/task') {
      return MENU_CATEGORY.CARE_PRO_TAXPAYERS
    } else if (pathname.includes('/pro/book')) {
      return MENU_CATEGORY.CARE_PRO_BOOK
    } else if (pathname.includes('/pro/payroll')) {
      return MENU_CATEGORY.CARE_PRO_PAYROLL
    } else if (pathname === '/pro/documents' || pathname === '/pro/certificate') {
      return MENU_CATEGORY.CARE_PRO_CERTIFICATION
    } else if (pathname.includes('/pro/files')) {
      return MENU_CATEGORY.CARE_PRO_FILE
    } else if (pathname.includes('/pro/report')) {
      return MENU_CATEGORY.CARE_PRO_ALARM
    } else if (pathname.includes('/pro/crm')) {
      return MENU_CATEGORY.CARE_PRO_CRM
    } else {
      return undefined
    }
  }

  const { data } = useSession()
  const pathname = usePathname()
  const isShowNavBar = !pathname.includes('/fileview') && pathname !== '/'
  const { setOpen, open } = useSidebar()
  const [cookies, setCookie] = useCookies(['zent-console-menubar-open'])
  const [categoryOpen] = useState<MENU_CATEGORY | undefined>(checkInitialMenuCategoryOpen())

  const getMenuOpen = () => {
    setTimeout(() => {
      setOpen(cookies['zent-console-menubar-open'] !== 'close' && isShowNavBar)
    }, 50)
  }

  const doSignout = async () => {
    await signOut()
  }

  useEffect(() => {
    setCookie('zent-console-menubar-open', open ? 'open' : 'close')
  }, [open])

  useEffect(() => {
    getMenuOpen()
  }, [])

  return (
    <Sidebar>
      <SidebarHeader>
        <div className="flex justify-center w-1/2 items-end text-red-800 text-3xl font-bold">BRICS</div>
      </SidebarHeader>
      <SidebarContent className="overflow-x-hidden">
        {data &&
          data.user &&
          (data.user?.functions.includes(AuthFunction.PAGE_REFUND) ||
            data.user?.functions.includes(AuthFunction.PAGE_REFUND_DEAL)) && (
            <Collapsible className="group/refund" defaultOpen={categoryOpen === MENU_CATEGORY.REFUND_OP}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>종소세 경정청구</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/refund:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_REFUND_DEAL) && (
                        <SidebarMenuItem>
                          <SidebarMenuButton asChild>
                            <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/deal`}>환급</a>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      )}
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/refund`}>분석상세</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/small-business`}>중특감 설문결과</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/csform/startup-business`}>창중감 설문결과</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_SALES) && (
          <Collapsible className="group/sales" defaultOpen={categoryOpen === MENU_CATEGORY.SALES}>
            <SidebarGroup>
              <SidebarGroupLabel>
                <CollapsibleTrigger className="flex justify-between w-full">
                  <div>영업관리</div>
                  <ChevronDown className="ml-auto transition-transform group-data-[state=open]/sales:rotate-180" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    <SidebarMenuItem>
                      <SidebarMenuButton asChild>
                        <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/channels`}>영업채널 관리</a>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                      <SidebarMenuButton asChild>
                        <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/salesman`}>영업자 관리</a>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                      <SidebarMenuButton asChild>
                        <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales`}>영업 현황</a>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                      <SidebarMenuButton asChild>
                        <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/sales/settlement`}>정산관리</a>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_ONEMIN) && (
          <SidebarMenu className="ml-2">
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/1min`}>1분 환급</a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CORPTAX) && (
          <SidebarMenu className="ml-2">
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/corptax`}>법인세 경정청구</a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_RESOURCE_CENTER) && (
          <SidebarMenu className="ml-2">
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/resource-center`}>리소스 센터</a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_ENVIRONMENT_VAR) && (
          <SidebarMenu className="ml-2">
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/environment-variables`}>환경변수 관리</a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_REFUND_LANDING_MODAL) && (
          <Collapsible className="group/sales" defaultOpen={categoryOpen === MENU_CATEGORY.REFUND}>
            <SidebarGroup>
              <SidebarGroupLabel>
                <CollapsibleTrigger className="flex justify-between w-full">
                  <div>비즈넵 환급</div>
                  <ChevronDown className="ml-auto transition-transform group-data-[state=open]/sales:rotate-180" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    <SidebarMenuItem>
                      <SidebarMenuButton asChild>
                        <a href={`${NEXT_PUBLIC_CONSOLE_REFUND_URL}/refund-service/advertisement/landing-modals`}>
                          랜딩 모달 관리
                        </a>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        )}
        {isContainsCareAuth() && (
          <Collapsible className="group/care" defaultOpen={categoryOpen === MENU_CATEGORY.CARE}>
            <SidebarGroup>
              <SidebarGroupLabel>
                <CollapsibleTrigger className="flex justify-between w-full">
                  <div>비즈넵 케어</div>
                  <ChevronDown className="ml-auto transition-transform group-data-[state=open]/care:rotate-180" />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_SUBSCRIPTION) && (
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/subscription`}>케어 구독현황</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    )}
                    {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_PAYMENT) && (
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/payments`}>결제관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    )}
                    {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_CMS) && (
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/cms`}>CMS 출금동의 내역</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    )}
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        )}
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_PRO) && (
          <Fragment>
            <Collapsible
              className="group/careprobusiness"
              defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_BUSINESS}
            >
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-사업장관리</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprobusiness:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org`}>수임사업장 관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/cms`}>출금동의서 조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/info`}>사업장정보 조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/org/taxinfo`}>사업장 휴폐업 일괄조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
            <Collapsible
              className="group/careprotaxpayer"
              defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_TAXPAYERS}
            >
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-업무 관리</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprotaxpayer:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/task`}>작업 관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer`}>종소세 신고서 관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/vat`}>부가세 신고자 관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/documents`}>홈택스 접수증/납부서</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/taxpayer/wetax/documents`}>
                            위택스 접수증/납부서
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible className="group/careprobook" defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_BOOK}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-장부/부가세</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprobook:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book`}>장부작성</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/bill/duplicate`}>중복증빙조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/status`}>장부 요약 분석</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/bill/summary`}>증빙합계조회(홈택스)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/vat/service`}>신고도움조회(홈택스)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/declare/vat`}>부가세신고</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/declare/taxfree`}>면세사업장현황신고</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/card`}>사업자신용카드 등록/조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/book/car`}>차량조회</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible className="group/carepropayroll" defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_PAYROLL}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-급여/원천세</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/carepropayroll:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/employee`}>직원명부</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/4insure`}>4대보험</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/response`}>급여제출내역</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/salary/beforetax`}>근로소득</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/daily`}>일용소득</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/biz`}>사업소득</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/etc`}>기타소득</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/withholding/half`}>
                            원천징수신고서(반기)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment`}>간이지급명세서(월)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment/employee`}>
                            간이지급명세서(반기)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/payroll/payment/year`}>지급명세서)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible
              className="group/careprocert"
              defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_CERTIFICATION}
            >
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-증명/신고서</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprocert:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/documents`}>신고서조회(전체)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/certificate`}>증명발급</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible className="group/careprofile" defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_FILE}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-파일관리</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprofile:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/files`}>파일관리</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/files/submission`}>제출내역</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible className="group/careproalarm" defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_ALARM}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-알림톡 발송</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careproalarm:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/chat`}>가입상담</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/apply`}>가입신청(상담완료)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/notpaid`}>기장료 미수안내</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/notpaidlong`}>
                            기장료 미수안내(기장종료)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/monthly`}>월간매출매입보고서</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/calendar`}>세무일정</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/bizcard`}>사업용신용카드</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/request/new`}>
                            급여제출요청(통합)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/request`}>급여제출요청(알바)</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/payroll/regular/request`}>
                            급여제출요청(정규직)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/wedbill/request`}>경조사비제출요청</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/etcbill/request`}>영수증제출요청</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/incometax/guide`}>
                            종합소득세신고안내문(기장)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/incometax/guide/declare`}>
                            종합소득세신고안내문(신고)
                          </a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/report/prevat`}>부가세중간예납안내</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>

            <Collapsible className="group/careprocrm" defaultOpen={categoryOpen === MENU_CATEGORY.CARE_PRO_CRM}>
              <SidebarGroup>
                <SidebarGroupLabel>
                  <CollapsibleTrigger className="flex justify-between w-full">
                    <div>케어프로-CRM관리</div>
                    <ChevronDown className="ml-auto transition-transform group-data-[state=open]/careprocrm:rotate-180" />
                  </CollapsibleTrigger>
                </SidebarGroupLabel>
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton asChild>
                          <a href={`${NEXT_PUBLIC_CONSOLE_CARE_URL}/pro/crm`}>CRM 목록</a>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          </Fragment>
        )}
        <SidebarGroup>
          <SidebarGroupLabel>
            <SidebarMenuButton asChild>
              <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/externals`}>외부링크</a>
            </SidebarMenuButton>
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <a href="https://bznav.com" target="_blank">
                    비즈넵(SME)
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <a href="https://bznav.com/tax/refund" target="_blank">
                    비즈넵 환급
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <a href="https://care.bznav.com/" target="_blank">
                    비즈넵 케어
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <a href="https://help.bznav.com/hc/ko" target="_blank">
                    비즈넵 고객센터(구 헬프센터)
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {data && data.user && data.user?.functions.includes(AuthFunction.PAGE_CARE_PRO) && (
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <a href="https://ai.bznav.com/payroll/edit" target="_blank">
                      간이 급여명세서
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <a href="https://ai.livein.cloud/ocr" target="_blank">
                      증빙 AI 추출
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <a href="https://ai.bznav.com" target="_blank">
                      세나 AI
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        )}
        {data && data.user && data.user?.functions.includes('PAGE_ADMIN_USER') && (
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <a href={`${NEXT_PUBLIC_CONSOLE_OP_URL}/admin/users`}>
                      <UserRoundCog className="w-4 h-4 mr-2" />
                      BRICS 권한 관리
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        )}
      </SidebarContent>
      <SidebarFooter>
        <Button variant="ghost" onClick={doSignout}>
          로그아웃
        </Button>
      </SidebarFooter>
    </Sidebar>
  )
}
export default RootSidebar
